import React, { Component } from "react";

import {
  Row,
  Col,
  Table,
  FormGroup,
  Label,
  Input,
  Button,
  ButtonGroup,
} from "reactstrap";

import ReactPaginate from "react-paginate";
import moment from "moment";
import { API_ENDPOINT } from "../constants";

class ListBuilder extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() { }

  render() {
    let params = this.props.params;
    return (
      <Row className="list-builder-wrap">
        <Col lg="12" className="top">
          {/*<div className="title">
                        <h2>{this.props.title}</h2>
                        {
                            this.props.button ?
                                <button onClick={this.props.button.action}>{this.props.button.text}</button>
                                :
                                null
                        }
                        
                    </div> */}
          <div>
            {this.props.sortEnabled ? (
              <div className="sort-wrap">
                <span>Sortiranje:</span>
                <ButtonGroup>
                  {this.props.fields.map((item, idx) => {
                    if (item.allowSort)
                      return (
                        <Button
                          className={
                            this.props.sortField == item.name &&
                              parseInt(this.props.sortType) == 1
                              ? `sort sort-asc`
                              : this.props.sortField == item.name &&
                                parseInt(this.props.sortType) == -1
                                ? "sort sort-desc"
                                : item.allowSort
                                  ? "sort"
                                  : ""
                          }
                          onClick={() => {
                            this.props.updateSort(
                              item.name,
                              !this.props.sortField
                                ? 1
                                : this.props.sortField == item.name &&
                                  parseInt(this.props.sortType) == 1
                                  ? -1
                                  : 1
                            );
                          }}
                          key={idx}
                        >
                          {item.sortLabel}
                        </Button>
                      );
                  })}
                </ButtonGroup>
              </div>
            ) : null}
          </div>
        </Col>

        <Col lg="12" className="table-container">
          <Table
            responsive
            borderless
            hover
            className={!this.props.actions ? "cursor-row" : ""}
          >
            <thead className="table-header"></thead>
            <tbody>
              {this.props.items && this.props.items.length
                ? this.props.items.map((item, idx) => {
                  return (
                    <tr
                      key={idx}
                      onClick={() => {
                        if (!this.props.actions && this.props.onClick) {
                          if (this.props.rawItems)
                            this.props.onClick(this.props.rawItems[idx]);
                          else this.props.onClick(item);
                        }
                      }}
                    >
                      {this.props.showNumeration ? (
                        <th scope="row">
                          {params.page * params.entries + idx + 1}
                        </th>
                      ) : null}
                      {this.props.fields.map((field, fidx) => {
                        if (field.type == "text") {
                          return (
                            <td key={fidx} className={field.className}>
                              {item[field.name]}
                            </td>
                          );
                        } else if (field.type == "list") {
                          return (
                            <td key={fidx}>
                              {item[field.name].map((fitem, fitemidx) => {
                                return (
                                  <div className="list-item">
                                    {fitem[field.itemName]}

                                    {field.actions.map((action, aidx) => {
                                      return (
                                        <button
                                          key={aidx}
                                          onClick={() =>
                                            action.onClick(item, fitemidx)
                                          }
                                        >
                                          {action.component}
                                        </button>
                                      );
                                    })}
                                  </div>
                                );
                              })}
                            </td>
                          );
                        } else if (field.type == "image") {
                          return (
                            <img
                              className="list-image"
                              key={fidx}
                              src={API_ENDPOINT + item[field.name][0]}
                            ></img>
                          );
                        }
                      })}

                      {this.props.actions ? (
                        <td className={`action-td`}>
                          {this.props.actions && this.props.actions.length
                            ? this.props.actions.map((action, aidx) => {
                              if (action.type == "functionAction") {
                                return action.render(item);
                              } else {
                                if (
                                  !action.condition ||
                                  (action.condition &&
                                    action.condition(item))
                                )
                                  return (
                                    <button
                                      key={aidx}
                                      onClick={() => {
                                        if (this.props.rawItems) {
                                          action.onClick(
                                            this.props.rawItems[idx]
                                          );
                                        } else {
                                          action.onClick(item);
                                        }
                                      }}
                                    >
                                      {action.component}
                                    </button>
                                  );
                              }
                            })
                            : null}
                        </td>
                      ) : null}
                    </tr>
                  );
                })
                : null}
            </tbody>
          </Table>
          {this.props.loading ? (
            <div className="loader-wrap">
              <div className="loader" />
            </div>
          ) : null}
        </Col>
        <Col lg="6">
          <ReactPaginate
            previousLabel={""}
            nextLabel={""}
            breakLabel={"..."}
            breakClassName={" disabled"}
            breakLinkClassName={" disabled"}
            pageCount={this.props.total ? this.props.total / params.entries : 1}
            marginPagesDisplayed={1}
            pageRangeDisplayed={2}
            onPageChange={(page) => {
              this.props.updateParams("page", page.selected);
            }}
            containerClassName={"pagination"}
            subContainerClassName={"pages pagination"}
            activeClassName={"active"}
            forcePage={parseInt(params.page)}
          />
        </Col>
        <Col lg="6" className="align-right">
          <span className="pagination-map">
            {"Prikaz"} {this.props.total ? params.page * params.entries + 1 : 0}{" "}
            {"do"}{" "}
            {this.props.total <
              params.page * params.entries + params.entries * 1
              ? this.props.total
              : params.page * params.entries + params.entries * 1}{" "}
            {"od"} <span className="orange"> {this.props.total} </span>{" "}
            {"stavki"}
          </span>
        </Col>
      </Row>
    );
  }
}

export default ListBuilder;
