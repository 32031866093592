import React, { Component } from 'react'
import Link from '../components/link'

import Isvg from 'react-inlinesvg'
import Page from '../containers/page'

import { Container, Row, Col } from 'reactstrap'

import FormBuilder from '../components/forms/formBuilder'
import { required } from '../components/forms/validation'
import { API_ENDPOINT } from '../constants'

import mainlogo from '../assets/svg/main-logo.svg'

class LoginPage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      promotedProjects: [],
      ...props.initialData,
    }
  }

  componentDidMount() {
    if (typeof window !== 'undefined') {
      window.scrollTo(0, 0)
    }

    for (let i = 0; i < this.props.loadData.length; i++) {
      this.props.loadData[i](
        window.fetch,
        this.props.lang,
        this.props[0].match
      ).then((data) => {
        this.setState(
          {
            ...data,
          },
          () => {
            this.props.updateMeta(
              this.props.generateSeoTags(this.state, this.props.lang)
            )
          }
        )
      })
    }
  }

  login = (data) => {
    console.log("prolazi kroz login");
    fetch(API_ENDPOINT + '/auth/login', {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.error) {
          this.setState({
            error: result.error,
          })
        } else {
          localStorage.setItem('authToken', result.token)
          this.props.verifyUser()
          this.props[0].history.push('/')
        }
      })
  }

  render() {
    return (
      <div className='login-page'>
        <Container>
          <Row>
            <Col lg='10' className='right'>
              <div class='main-form-container'>
                <div class='logo'>
                  <Isvg src={mainlogo} />
                </div>
                <h5 className='form-header'>Login</h5>
                <FormBuilder
                  onSubmit={(data) => {
                    this.login(data)
                  }}
                  //buttonText={'Uloguj se'}
                  fields={[
                    {
                      type: 'row',
                      children: [
                        {
                          type: 'col',
                          width: {
                            lg: 12,
                            sm: 12,
                            xs: 12,
                          },
                          children: [
                            {
                              type: 'text',
                              name: 'username',
                              label: 'Korisničko ime *',
                              placeholder: 'Unesite korisničko ime',
                              validate: [
                                required('Korisničko ime je obavezno!'),
                              ],
                            },
                          ],
                        },
                        {
                          type: 'col',
                          width: {
                            lg: 12,
                            sm: 12,
                            xs: 12,
                          },
                          children: [
                            {
                              type: 'password',
                              name: 'password',
                              label: 'Lozinka *',
                              placeholder: 'Unesite lozinku',
                              validate: [required('Lozinka je obavezna!')],
                            },
                          ],
                        },
                        {
                          type: 'col',
                          width: {
                            lg: 6,
                            sm: 6,
                            xs: 6,
                          },
                          children: [
                            {
                              type: 'checkbox',
                              name: 'remember',
                              afterText: (
                                <span className='passwrod-label'>
                                  {' '}
                                  Zapamti me{' '}
                                </span>
                              ),
                            },
                          ],
                        },
                        {
                          type: 'col',
                          width: {
                            lg: 6,
                            sm: 6,
                            xs: 6,
                          },
                          children: [
                            {
                              type: 'hidden',
                              name: 'remember',
                              afterText: (
                                <a href='#' className='password-label'>
                                  {' '}
                                  Zaboravili ste šifru?{' '}
                                </a>
                              ),
                            },
                          ],
                        },
                        {
                          type: 'col',
                          width: {
                            lg: 12,
                            sm: 12,
                            xs: 12,
                          },
                          children: [
                            {
                              type: 'submit-button',
                              text: 'Uloguj se',
                            },
                          ],
                        },
                      ],
                    },
                  ]}
                ></FormBuilder>
                <p className='error-message'>{this.state.error}</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
}

export default Page(LoginPage)
