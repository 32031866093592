import React, { Component } from 'react'
import Page from '../containers/page'

import { Container, Row, Col, Input } from 'reactstrap'

import Isvg from 'react-inlinesvg'

import FormBuilder from '../components/forms/modalFormBuilder'
import { required } from '../components/forms/validation'
import Search from '../components/search'
import { Link } from 'react-router-dom'

import ListBuilder from '../components/listBuilder'
import DeleteModal from '../components/deleteModal'
import {
    getSearchParams,
    generateSearchLink,
    generateSearchLinkMultiple,
} from '../helpers/linkHelper'
import { API_ENDPOINT } from '../constants'

import {SortableContainer, SortableElement} from 'react-sortable-hoc';

import editIcon from '../assets/svg/edit.svg'
import garabageIcon from '../assets/svg/garabage.svg'
import userIcon from '../assets/svg/user-icon.svg'
import notification from '../assets/svg/notification.svg'
import profile from '../assets/svg/profile-image.svg'
import moreIcon from '../assets/svg/more.svg'
import closeIcon from '../assets/svg/x.svg'
import rightIcon from '../assets/svg/arrow-right.svg'
import map from '../assets/svg/map.svg'
import arrayMove from 'array-move';
import chart from '../assets/svg/chart.svg'
import SwitchButton from '../components/forms/fields/toggle.js'

const SortableItem = SortableElement(({ value, update, lang, className, onSortStart1, dragIndex1, parent }) => <li className={className}> <h6> {value.categoryName}</h6>
    {
        value.submenu && value.submenu.length ?
            <SortableList onSortStart={({node, index}) => {
                onSortStart1(parent, index)
            }} dragIndex={dragIndex1} hideSortableGhost={false} lang={lang} onSortEnd={({ oldIndex, newIndex }) => update(oldIndex, newIndex)}
                items={value.submenu}></SortableList>
            :
            null
    }

</li>);

const SortableList = SortableContainer(({ items, updateSubMenu, lang, dragIndex, onSortStart1, dragIndex1 }) => {
    return (
        <ul className="sort-list">
            {items.map((value, index) => (
                <SortableItem parent={index} onSortStart1={onSortStart1} dragIndex1={dragIndex1 && dragIndex1.parent === index ? dragIndex1.index : null} className={dragIndex === index ? 'drag' : null} key={`item-${value}`} index={index} value={value} lang={lang} update={(oldIndex, newIndex) => updateSubMenu(index, oldIndex, newIndex)} itemIndex={index} />
            ))}
            
        </ul>
    );
});

class SortCategory extends Component {
    constructor(props) {
        super(props)

        this.state = {
            ...props.initialData,
            form: false,
            useState: false,
            params: {
                entries: 10,
                page: 0,
            },
            items: [],
            areas: [],
            total: 0,
            loading: true,
            newsData: [],
        }
    }

    get = () => {
        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](
                window.fetch,
                this.props.lang,
                this.props[0].match,
                this.state.useState
                    ? this.state.params
                    : getSearchParams(this.props[0].location.search, {
                        page: 0,
                    })
            ).then((data) => {
                this.setState(
                    {
                        ...data,
                        loading: null,
                    },
                    () => {
                        this.props.updateMeta(
                            this.props.generateSeoTags(this.state, this.props.lang)
                        )
                    }
                )
            })
        }

        for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
            this.props.loadDataWithQuery[i](
                window.fetch,
                this.props.lang,
                this.props[0].match,
                this.state.useState
                    ? this.state.params
                    : getSearchParams(this.props[0].location.search, {
                        page: 0,
                    })
            ).then((data) => {
                this.setState(
                    {
                        ...data,
                        loading: null,
                    },
                    () => {
                        this.props.updateMeta(
                            this.props.generateSeoTags(this.state, this.props.lang)
                        )
                    }
                )
            })
        }
    }

    componentDidMount() {
        if (typeof window !== 'undefined') {
            window.scrollTo(0, 0)
        }

        this.get()
    }

    componentDidUpdate(prevProps) {
        if (prevProps[0].location.search != this.props[0].location.search) {
            this.setState(
                {
                    loading: true,
                },
                () => {
                    this.get()
                }
            )
        }
    }

    updateSort = (field, type) => {
        if (this.state.useState) {
            let state = this.state.params
            state.sortField = field
            state.sortType = type
            this.setState({
                params: state,
            })
        } else {
            this.props[0].history.push(
                generateSearchLinkMultiple(
                    this.props[0].location.search,
                    { entries: 10, page: 0 },
                    [
                        { name: 'sortField', value: field },
                        { name: 'sortType', value: type },
                    ],
                    false
                )
            )
        }
    }

    updateParams = (name, value, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0,
                }
                obj[name] = value
                this.setState({ params: obj })
            } else {
                let obj = this.state.params
                obj[name] = value
                this.setState({ params: obj })
            }
        } else {
            this.props[0].history.push(
                generateSearchLink(
                    this.props[0].location.search,
                    { entries: 10, page: 0 },
                    name,
                    value,
                    restart
                )
            )
        }
    }

    updateMultipleParams = (fields, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0,
                }
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value
                }
                this.setState({ params: obj })
            } else {
                let obj = this.state.params
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value
                }
                this.setState({ params: obj })
            }
        } else {
            this.props[0].history.push(
                generateSearchLinkMultiple(
                    this.props[0].location.search,
                    { entries: 10, page: 0 },
                    fields,
                    restart
                )
            )
        }
    }
    onSortEnd = ({ oldIndex, newIndex }) => {
        this.setState(({ items }) => ({
            items: arrayMove(items, oldIndex, newIndex),
            dragIndex: null
        }));
    };

    updateSubMenu = (index, oldIndex, newIndex) => {
        console.log(index, oldIndex, newIndex)
        let items = this.state.items;
        let newItems = this.state.items[index].submenu;
        newItems = arrayMove(newItems, oldIndex, newIndex);
        items[index].submenu = newItems;
        this.setState({
            items,
            dragIndex1: null
        })

    };

    updatePosition = () => {
        this.setState(
            () => {
                let positions = [];
                let items = this.state.items;
                for (let i = 0; i < items.length; i++) {
                    positions.push({
                        _id: items[i]._id,
                        position: i
                    });
                    if (items[i].submenu && items[i].submenu.length) {
                        for (let j = 0; j < items[i].submenu.length; j++) {
                            positions.push({
                                _id: items[i].submenu[j]._id,
                                position: j
                            })
                        }
                    }
                }

                fetch(API_ENDPOINT + '/modules/sort', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({modules: positions})
                }).then(res => res.json()).then((result) => {
                    this.setState(
                        {
                          message: 'Modules updated successfully',
                          //message: result.message,
                        },
                      );
                    this.get();
                    this.props.verifyUser();
                })
            }
        )
    }

    render() {
        let params = {}
        if (this.state.useState) {
            params = this.state.params
        } else {
            params = getSearchParams(this.props[0].location.search, {
                entries: 10,
                page: 0,
            })
        }


        return (
            <div className='page'>
                <Container className={this.props.acitveSidebar ? 'dashboard' : 'dashboard padd-change'}>
                    <Row>
                        <Col lg='9'>
                            <Search updateParams={this.updateParams} />
                            <div className='panel'>
                                <div className='main-table-header'>
                                    <h5 className='component-header'>Sortiraj Kategorije</h5>
                                </div>

                                <SortableList onSortStart={({node, index}) => {
                                    this.setState({dragIndex: index})
                                }} 
                               
                                dragIndex = {this.state.dragIndex}
                                onSortStart1={(parent, index) => {
                                    console.log(parent, index)
                                    this.setState({dragIndex1: {parent: parent, index: index } })
                                }} 
                               
                                dragIndex1 = {this.state.dragIndex1}
                                hideSortableGhost={false} lang={this.props.lang} items={this.state.items} onSortEnd={this.onSortEnd} updateSubMenu={this.updateSubMenu} />

                                
                                <div className="sort-modules-save-button">
                                    <button className="main-form-button" onClick={this.updatePosition} color="primary">
                                    Sačuvaj
                                    </button>
                                </div>

                            </div>
                        </Col>
                        <Col lg='3'>

                            <div className='component notifications'>
                                <Isvg src={notification} />
                                <div className='profile'>
                                    <span className='name'>
                                        {this.props.uData.username}
                                    </span>
                                    <Isvg src={profile} className='profile-image' />
                                    <Isvg src={moreIcon} />
                                </div>
                            </div>

                        </Col>
                    </Row>
                </Container>

            </div>
        )
    }
}

export default Page(SortCategory)
