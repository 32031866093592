
import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';

import Isvg from 'react-inlinesvg';

import { FormGroup, Label, Input, FormFeedback, FormText } from 'reactstrap';


class Text extends Component {
    constructor(props) {
        super(props);

        this.state = {
        };
    }






    render() {
        return (
            <FormGroup>
                {this.props.label ? <Label size="sm" className={this.props.error ? "required" : ""}>{this.props.label}{false && this.props.haveValidation ? <span className="required-label-span">*</span> : null}</Label> : null}
                {
                    this.props.beforeText ? <span className="field-before-text">{this.props.beforeText}</span> : null
                }
                <Input size="sm" style={{ height: this.props.height ? this.props.height : null }} disabled={this.props.disabled} name={this.props.name} defaultChecked={this.props.optionValue == this.props.value} invalid={this.props.error ? true : false} type={this.props.type ? this.props.type : 'text'} multiple={this.props.multiple} onChange={(val) => {
                    /*if (this.props.type == 'radio') {
                        this.props.onChange(this.props.optionValue);
                        return;
                    }*/
                    this.props.onChange(val)
                }} value={this.props.value} placeholder={this.props.placeholder}>{this.props.children}</Input>
                {
                    this.props.afterText ? <span className="field-after-text">{this.props.afterText}</span> : null
                }
                {this.props.error ? <FormFeedback >{this.props.error}</FormFeedback> : null}
            </FormGroup>
        );
    }
}

export default Text;