import React, { Component } from "react";
import Page from "../containers/page";

import { Container, Row, Col, Input } from "reactstrap";

import Isvg from "react-inlinesvg";

import FormBuilder from "../components/forms/modalFormBuilder";
import { required } from "../components/forms/validation";
import Search from "../components/search";
import { Link } from "react-router-dom";

import ListBuilder from "../components/listBuilder";
import DeleteModal from "../components/deleteModal";
import {
  getSearchParams,
  generateSearchLink,
  generateSearchLinkMultiple,
} from "../helpers/linkHelper";
import { API_ENDPOINT } from "../constants";

import editIcon from "../assets/svg/edit.svg";
import garabageIcon from "../assets/svg/garabage.svg";
import userIcon from "../assets/svg/user-icon.svg";
import notification from "../assets/svg/notification.svg";
import profile from "../assets/svg/profile-image.svg";
import moreIcon from "../assets/svg/more.svg";
import closeIcon from "../assets/svg/x.svg";
import rightIcon from "../assets/svg/arrow-right.svg";
import map from "../assets/svg/map.svg";
import chart from "../assets/svg/chart.svg";
import SwitchButton from "../components/forms/fields/toggle.js";

class Users extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...props.initialData,
      form: false,
      useState: false,
      params: {
        entries: 10,
        page: 0,
      },
      items: [],
      areas: [],
      total: 0,
      loading: true,
      newsData: [],
      klasa: "",
    };
  }

  get = () => {
    for (let i = 0; i < this.props.loadData.length; i++) {
      this.props.loadData[i](
        window.fetch,
        this.props.lang,
        this.props[0].match,
        this.state.useState
          ? this.state.params
          : getSearchParams(this.props[0].location.search, {
            entries: 10,
            page: 0,
          })
      ).then((data) => {
        this.setState(
          {
            ...data,
            loading: null,
          },
          () => {
            this.props.updateMeta(
              this.props.generateSeoTags(this.state, this.props.lang)
            );
          }
        );
      });
    }

    for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
      this.props.loadDataWithQuery[i](
        window.fetch,
        this.props.lang,
        this.props[0].match,
        this.state.useState
          ? this.state.params
          : getSearchParams(this.props[0].location.search, {
            entries: 10,
            page: 0,
          })
      ).then((data) => {
        this.setState(
          {
            ...data,
            loading: null,
          },
          () => {
            this.props.updateMeta(
              this.props.generateSeoTags(this.state, this.props.lang)
            );
          }
        );
      });
    }
  };

  toggleSwitch = () => {
    const currentState = this.state.switch;
    this.setState({ switch: !currentState });
  };

  componentDidMount() {
    if (typeof window !== "undefined") {
      window.scrollTo(0, 0);
    }

    this.get();
  }

  handleOptionChange(event) {
    this.setState({ value: event.target.value });
  }

  componentDidUpdate(prevProps) {
    if (prevProps[0].location.search != this.props[0].location.search) {
      this.setState(
        {
          loading: true,
        },
        () => {
          this.get();
        }
      );
    }
  }

  updateSort = (field, type) => {
    if (this.state.useState) {
      let state = this.state.params;
      state.sortField = field;
      state.sortType = type;
      this.setState({
        params: state,
      });
    } else {
      this.props[0].history.push(
        generateSearchLinkMultiple(
          this.props[0].location.search,
          { entries: 10, page: 0 },
          [
            { name: "sortField", value: field },
            { name: "sortType", value: type },
          ],
          false
        )
      );
    }
  };

  updateParams = (name, value, restart = false) => {
    if (this.state.useState) {
      if (restart) {
        let obj = {
          entries: 10,
          page: 0,
        };
        obj[name] = value;
        this.setState({ params: obj });
      } else {
        let obj = this.state.params;
        obj[name] = value;
        this.setState({ params: obj });
      }
    } else {
      this.props[0].history.push(
        generateSearchLink(
          this.props[0].location.search,
          { entries: 10, page: 0 },
          name,
          value,
          restart
        )
      );
    }
  };

  updateMultipleParams = (fields, restart = false) => {
    if (this.state.useState) {
      if (restart) {
        let obj = {
          entries: 10,
          page: 0,
        };
        for (let i = 0; i < fields.length; i++) {
          obj[fields[i].name] = fields[i].value;
        }
        this.setState({ params: obj });
      } else {
        let obj = this.state.params;
        for (let i = 0; i < fields.length; i++) {
          obj[fields[i].name] = fields[i].value;
        }
        this.setState({ params: obj });
      }
    } else {
      this.props[0].history.push(
        generateSearchLinkMultiple(
          this.props[0].location.search,
          { entries: 10, page: 0 },
          fields,
          restart
        )
      );
    }
  };

  delete = (id) => {
    this.setState(
      {
        loading: true,
      },
      () => {
        fetch(API_ENDPOINT + "/data/comment/" + id, {
          method: "DELETE",
          headers: {
            "content-type": "application/json",
            Authorization:
              typeof localStorage !== "undefined"
                ? `Bearer ${localStorage.getItem("authToken")}`
                : null,
          },
        })
          .then((res) => res.json())
          .then((result) => {
            this.get();
          });
      }
    );
  };

  render() {
    let params = {};
    if (this.state.useState) {
      params = this.state.params;
    } else {
      params = getSearchParams(this.props[0].location.search, {
        entries: 10,
        page: 0,
      });
    }

    return (
      <div className="page">
        <Container
          className={
            this.props.acitveSidebar ? "dashboard" : "dashboard padd-change"
          }
        >
          <Row>
            <Col lg="9">
              <Search updateParams={this.updateParams} />
              <div className="panel">
                <div className="main-table-header">
                  <h5 className="component-header">Lista Komentara</h5>
                </div>

                <ListBuilder
                  loading={this.state.loading && false}
                  total={this.state.total}
                  showNumeration={false}
                  fields={[
                    {
                      type: "text",
                      class: "labelbam",
                      name: "username",
                      sortLabel: "Po korisnickom imenu",
                      allowSort: true,
                      className: 'naziv-korisnika'
                    },
                    {
                      type: "text",
                      class: "labelbam",
                      name: "comment",
                      sortLabel: "Po imenu",
                      allowSort: true,
                      className: 'naziv-komentara'
                    },
                    {
                      type: "text",
                      class: "labelbam",
                      name: "newsName",
                      sortLabel: "Po imenu",
                      allowSort: true,
                      className: 'naziv-vijesti'
                    },
                    {
                      type: "text",
                      class: "labelbam",
                      name: "switch",
                      className: 'prekidac'
                    },
                  ]}
                  rawItems={this.state.items}
                  items={this.state.items.map((item, idx) => {
                    return {
                      ...item,
                      source: (
                        <div>
                          <Isvg src={userIcon} className="name-icon" />
                          {item.source}
                        </div>
                      ),
                      switch: (
                        <SwitchButton
                          value={item.enabled}
                          onChange={(value) => {
                            fetch(API_ENDPOINT + "/data/comment/" + item._id, {
                              method: "POST",
                              headers: {
                                "content-type": "application/json",
                                Authorization:
                                  typeof localStorage !== "undefined"
                                    ? `Bearer ${localStorage.getItem(
                                      "authToken"
                                    )}`
                                    : null,
                              },
                            }).then((res) => this.props.getNumberOfComments());
                          }}
                        ></SwitchButton>
                      ),
                      name: item.name,
                    };
                  })}
                  actions={[
                    {
                      component: (
                        <Isvg src={garabageIcon} className="edit-icon" />
                      ),
                      onClick: (item) => this.setState({ deleteModal: item }),
                    },
                  ]}
                  params={params}
                  sortField={params.sortField}
                  sortType={params.sortType}
                  updateSort={this.updateSort}
                  updateParams={this.updateParams}
                  updateMultipleParams={this.updateMultipleParams}
                ></ListBuilder>
                <DeleteModal
                  isOpen={this.state.deleteModal}
                  toggle={() => this.setState({ deleteModal: null })}
                  handler={() => {
                    this.delete(this.state.deleteModal._id);
                    this.setState({ deleteModal: null });
                  }}
                >
                  Obrisi komentar{" "}
                  <strong>
                    {this.state.deleteModal && this.state.deleteModal.comment}
                  </strong>
                  ?
                </DeleteModal>
              </div>
            </Col>
            <Col lg="3">
              <div className="component notifications">
                <Isvg src={notification} />
                <div className="profile">
                  <span className="name">{this.props.uData.username}</span>
                  <Isvg src={profile} className="profile-image" />
                  <Isvg src={moreIcon} />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Page(Users);
